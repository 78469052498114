import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

import routes from "./routes";
// import logistics from "./logistics"
Vue.use(VueRouter);
Vue.use(VueMeta, {
  keyName: "page",
});

const router = new VueRouter({
  routes,
  // logistics,
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
// const role = 'user'
// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  const publicPages = ["/v2/login", "/v2/register", "/v2/forget-password"];
  // const subscription=["subscription/add_subscription"];
  const notAuthPath = !publicPages.includes(routeTo.path);
  const userInfo = localStorage.getItem("vendor_user");

//   console.log("notAuthPath:", notAuthPath, routeTo.path);
//   console.log("userInfo:", userInfo);
//   console.log("!userInfo:", !userInfo);
  if (notAuthPath) {
    if (!userInfo) {
      return next("/v2/login");
    } else {
      const userInfoObj = JSON.parse(userInfo);
      if (userInfoObj.store === null) {
        if (routeTo.path == "/shop/resident") {
          next();
        } else {
          return next("/shop/resident");
        }
      }
    }
  }

  next();
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }

  // If we reach this point, continue resolving the route.
  next();
});

export default router;

<template>
  <span> © 2022 Sokogate v{{ version }}</span>
</template>
<script>
import { GetAppVersionList } from "@/utils/api";
import { compareVersion } from "@/utils/";

export default {
  props: {
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
    };
  },
  created() {
    this.GetAppVersion();
  },
  methods: {
    GetAppVersion() {
      GetAppVersionList({})
        .then(({ data }) => {
          if (data && data[this.name]) {
            const { version } = data[this.name];
            // 版本对比
            const compare = compareVersion(this.version, version);
            // console.log("compare:", compare);
            if (compare >= 0) {
              // 当前版本可用
            } else {
              // this.$notify({
              //   type: "warning",
              //   title: this.$t("common.VersionUpdateTitle"),
              //   message: this.$t("common.VersionUpdateMessage"),
              //   showClose: false,
              // });
              // setTimeout(() => {
              //   // window.location.reload();
              // }, 100);

              this.$confirm(
                this.$t("common.VersionUpdateMessage"),
                `${this.$t("common.VersionUpdateTitle")} v${version}`,
                {
                  confirmButtonText: this.$t("common.VersionUpdateRefreshNow"),
                  type: "warning",
                  center: true,
                  showClose: false,
                  showCancelButton: false,
                  closeOnClickModal: false,
                }
              ).then(() => {
                window.location.reload();
              });
            }
          }
        })
        .catch((err) => {
          console.log("GetAppVersionList-err:", err);
        });
    },
  },
};
</script>

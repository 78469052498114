import moment from "moment";
import AsyncImport from "./AsyncImport.js";

export function jumpto(path) {
  console.log("process.env:", process.env);
  if (path.indexOf("https://") > -1 || path.indexOf("http://") > -1) {
    window.location.href = path;
  } else if (process.env.VUE_APP_V1_HOMEPAGE_URL) {
    window.location.href = process.env.VUE_APP_V1_HOMEPAGE_URL + path;
  } else {
    console.log(
      "process.env.VUE_APP_V1_HOMEPAGE_URL:",
      process.env.VUE_APP_V1_HOMEPAGE_URL
    );
  }
}

export function debounce(func, wait = 500) {
  let timeout;
  return function (event) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.call(this, event);
    }, wait);
  };
}

export function formatQuery(obj) {
  return Object.keys(obj)
    .map((key) => {
      return key + "=" + obj[key];
    })
    .join("&");
}

export function makeRoutes(list) {
  return list
    .map(({ menu, children }) => {
      return [
        {
          ...menu,
          component: AsyncImport(menu),
          meta: {
            layout: "default-layout",
          },
        },
      ].concat(
        children
          ? children.map(({ menu }) => {
            return {
              ...menu,
              component: AsyncImport(menu),
              meta: {
                layout: "default-layout",
              },
            };
          })
          : []
      );
    })
    .reduce((a, b) => {
      return a.concat(b);
    });
}

export function compareVersion(v1, v2) {
  v1 = v1.split(".");
  v2 = v2.split(".");
  const len = Math.max(v1.length, v2.length);

  while (v1.length < len) {
    v1.push("0");
  }
  while (v2.length < len) {
    v2.push("0");
  }

  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1[i]);
    const num2 = parseInt(v2[i]);

    if (num1 > num2) {
      return 1;
    } else if (num1 < num2) {
      return -1;
    }
  }

  return 0;
}

export const findCategoryChildren = (_id, list) => {
  return list
    .filter((v) => v.parent === _id)
    .map((v) => {
      const children = findCategoryChildren(v._id, list);
      const options = children.length ? { children: children } : {};
      return {
        value: v._id,
        label: v.categoryName,
        ...options,
      };
    });
};

export const findCategorySelectChildren = (_id, list) => {
  return list
    .filter((v) => v.parentId === _id)
    .map((v) => {
      const children = findCategorySelectChildren(v.id, list);
      const options = children.length ? { children: children } : {};
      return {
        value: v.id,
        label: v.categoryName,
        ...options,
      };
    });
};

export function isEmpty(v) {
  switch (typeof v) {
    case "undefined":
      return true;
    case "string":
      if (v.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, "").length == 0)
        return true;
      break;
    case "boolean":
      if (!v) return true;
      break;
    case "number":
      if (isNaN(v)) return true;
      break;
    case "object":
      if (null === v || v.length === 0) return true;
      for (var i in v) {
        return false;
      }
      return true;
  }
  return false;
}

// 两位小数
export function formatToDecimal(num) {
  // 格式化为小数，单位分 -> 元
  const float = parseFloat(num);
  if (isNaN(float)) {
    return 0;
  }
  return Math.round(num) / 100;
}

export function keepTwoDecimal(num) {
  const xsd = num.toString().split(".");
  // console.log('xsd:', xsd);
  if (xsd.length === 1) {
    return num.toString() + ".00";
  }
  if (xsd.length > 1) {
    if (xsd[1].length < 2) {
      num = num.toString() + "0";
    } else if (xsd[1].length > 2) {
      num = num.toFixed(2);
    }
    return num;
  }
}

// 四位小数
export function formatToDecimalTofour(num) {
  // 格式化为小数，单位分 -> 元
  const float = parseFloat(num);
  if (isNaN(float)) {
    return 0;
  }
  return Math.round(num) / 10000;
}

export function formatDataTime(unix) {
  // 格式化时间,戳转日期时间
  return moment.unix(unix).format("YYYY-MM-DD HH:mm:ss");
}

export function recoverCmbNum(string, num = 1) {
  const [l, w, h] = string.split("*");
  if (l && w && h) {
    return Number(l) * Number(w) * Number(h) * num;
  } else {
    return 0;
  }
}

//时间戳日期格式化
export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  let o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}
function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}

export function navto(path, query = {}) {
  window.vm.$router.push({
    path,
    query,
  });
}

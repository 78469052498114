<template>
  <div class="header">
    <el-drawer
      :visible="showDrawer"
      :size="250"
      direction="ltr"
      :with-header="false"
      :before-close="handleClose"
    >
      <nav-left @select="handleClose"></nav-left>
    </el-drawer>
    <div class="header-left">
      <el-button
        class="drawer-btn"
        icon="el-icon-s-unfold"
        circle
        @click="drawerOpen"
      ></el-button>
      <span style="font-size: 14px; color: #777; padding-right: 20px"
        ><version-no name="Vendor_Web"></version-no
      ></span>
    </div>
    <div class="header-right">
      <div class="switch-language-btn"><languag-switch /></div>
      <span class="username" @click="updateStorePrifle">{{ name }}</span>
      <span class="avatar" @click="updateStorePrifle">
        <img v-if="img" :src="img + '?x-oss-process=style/w128'" class="img" />
        <el-avatar
          v-else
          :size="40"
          src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
        ></el-avatar>
      </span>
      <el-button
        icon="sokogate icon-exit"
        circle
        @click="exitPopUp"
      ></el-button>
    </div>
  </div>
</template>

<script>
import NavLeft from "@/components/Navleft";
import VersionNo from "@/components/VersionNo";
import LanguagSwitch from "@/components/LanguagSwitch";

export default {
  components: {
    NavLeft,
    VersionNo,
    LanguagSwitch,
  },

  data() {
    return {
      showDrawer: false,
      input: "",
      name: localStorage.getItem("name"),
      img: localStorage.getItem("img"),
    };
  },

  methods: {
    // 退出登录
    exitPopUp() {
      this.$confirm(this.$t("common.logoutTips"), this.$t("common.tips"), {
        confirmButtonText: this.$t("common.logout"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
        center: true,
      }).then(() => {
        this.remove();
        this.$message({
          type: "success",
          message: this.$t("common.logoutSuccess"),
        });
      });
    },
    remove() {
      localStorage.clear();
      // this.$router.push("/login");
      window.location.href = "/v2/login";
    },
    updateStorePrifle() {
      this.$router.push("/account/personal");
    },
    drawerOpen() {
      this.showDrawer = true;
    },
    handleClose() {
      this.showDrawer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/_responsive.scss";

.header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-left {
  display: inline-flex;
  align-items: center;
}
.header-right {
  display: inline-flex;
  align-items: center;
}
.header-right .avatar {
  float: right;
  margin-right: 15px;
  margin-top: 8px;
  cursor: pointer;
}
.header-right .avatar .img {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  cursor: pointer;
}
.header-right .username {
  float: right;
  margin-top: 2px;
  margin-right: 20px;
  cursor: pointer;

  @include mobile {
    display: none;
  }
}
.switch-language-btn {
  margin-right: 10px;
}
.drawer-btn {
  display: none;
  @include mobile {
    display: inline-block;
  }
}
</style>
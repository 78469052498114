export default [
  // // 登录
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("../views/login.vue"),
  // },
  // // 忘记密码
  // {
  //   path: "/forgetPassword",
  //   name: "forgetPassword",
  //   component: () => import("../views/forgetPassword.vue"),
  // },
  // // 注册
  // {
  //   path: "/register",
  //   name: "register",
  //   component: () => import("../views/register.vue"),
  // },
  // 登录
  {
    path: '/v2/login',
    name: 'userLogin',
    component: () => import('@/views/user/login')
  },
  // 注册
  {
    path: '/v2/register',
    name: 'userRegister',
    component: () => import('@/views/user/register')
  },
  // 忘记密码
  {
    path: '/v2/forget-password',
    name: 'userForgetPassword',
    component: () => import('@/views/user/forgetPassword')
  },

  // //  入驻
  // {
  //   path: "/resident",
  //   name: "resident",
  //   component: () => import("../views/resident.vue"),
  //   meta: {
  //     keepAlive: true, // 需要缓存
  //   },
  // },

  // 首页 index
  {
    path: "/",
    name: "index",
    component: () => import("@/views/index/index.vue"),
    meta: {
      layout: "default-layout",
    },
  },

  // shop入驻
  {
    path: "/shop/resident",
    name: "shopresident",
    component: () => import("@/views/shop/resident"),
    meta: {
      keepAlive: true, // 需要缓存
    },
  },

  // 后台
  /*  {
      path: "/",
      name: "layout",
      component: () => import("../views/layout"),
      redirect: "/index",
      children: [
        //个人 account
        {
          path: "account/personal",
          name: "account_personal",
          meta: {
            roles: ['admin', 'user']
          },
          component: () => import('@/views/account/personal.vue'),
        },
        {
          path: "account/change",
          name: "account_change",
          component: () => import('@/views/account/change.vue'),
        },
        {
          path: "account/roleDatail",
          name: "account_roleDatail",
          component: () => import('@/views/account/roleDatail.vue'),
        },
        {
          path: "account/personnelDatail",
          name: "account_personnelDatail",
          component: () => import('@/views/account/personnelDatail.vue'),
        },
        {
          path: "account/permission",
          name: "account_permission",
          component: () => import('@/views/account/permission.vue'),
  
        },
  
        // 订单 orders
        {
          path: "orders/commodity",
          name: "orders_commodity",
          meta: {
            roles: ['admin']
          },
          component: () => import('@/views/orders/commodity.vue'),
        },
        {
          path: "orders/orderDetail",
          name: "orders_orderDetail",
          component: () => import('@/views/orders/orderDetail')
        },
        {
          path: "orders/evaluation",
          name: "orders_evaluation",
          component: () => import('@/views/orders/evaluation.vue'),
        },
        {
          path: "orders/ship",
          name: "orders_ship",
          component: () => import('@/views/orders/ship.vue'),
        },
  
  
        // // 产品 commodity
        {
          path: "commodity/release",
          name: "commodity_release",
          component: () => import('@/views/commodity/release.vue'),
        },
        {
          path: "commodity/releaseimg",
          name: "commodity_releaseimg",
          component: () => import('@/views/commodity/releaseimg')
        },
        {
          path: "commodity/products",
          name: "commodity_products",
          component: () => import('@/views/commodity/products.vue'),
        },
        {
          path: "commodity/productEdit",
          name: "commodity_productEdit",
          component: () => import('@/views/commodity/productEdit.vue'),
        },
        {
          path: "commodity/category",
          name: "commodity_category",
          component: () => import('@/views/commodity/category.vue'),
        },
        {
          path: "commodity/brand",
          name: "commodity_brand",
          component: () => import('@/views/commodity/brand.vue')
        },
  
        // // 店铺 shop 
        {
          path: "shop/decoration",
          name: "shop_decoration",
          component: () => import('@/views/shop/decoration'),
        },
        {
          path: "shop/decorations",
          name: "shop_decorations",
          component: () => import('@/views/shop/decorations'),
        },
        {
          path: "shop/picture",
          name: "shop_picture",
          component: () => import('@/views/shop/picture.vue'),
        },
        {
          path: "shop/store",
          name: "shop_store",
          component: () => import('@/views/shop/store.vue'),
        },
        {
          path: "shop/category",
          name: "shop_category",
          component: () => import('@/views/shop/category.vue')
        },
        {
          path: "shop/subscription",
          name: "shop_subscription",
          component: () => import('@/views/shop/subscription.vue'),
        },
        // 采购 purchase
        {
          path: "purchase/procurement",
          name: "purchase_procurement",
          component: () => import('@/views/purchase/procurement'),
        },
        {
          path: "purchase/purchasing",
          name: "purchase_purchasing",
          component: () => import('@/views/purchase/purchasing'),
        },
        {
          path: "purchase/purchaseDetail",
          name: "purchase_purchaseDetail",
          component: () => import('@/views/purchase/purchaseDetail'),
        },
        {
          path: "purchase/quotation",
          name: "purchase_quotation",
          component: () => import('@/views/purchase/quotation'),
        },
        {
          path: "purchase/procurementDetail",
          name: "purchase_procurementDetail",
          component: () => import('@/views/purchase/procurementDetail'),
        },
        // 询盘 inquiry
        {
          path: "inquiry/inquiry",
          name: "inquiry_inquiry",
          component: () => import('@/views/inquiry/inquiry')
        },
        {
          path: "inquiry/reply",
          name: "inquiry_reply",
          component: () => import("@/views/inquiry/reply")
        },
        {
          path: "inquiry/inquiry_view",
          name: "inquiry_inquiry_view",
          component: () => import("@/views/inquiry/inquiry_view")
        },
        // {
        //   path: "apps/coupon",
        //   name: "apps/coupon",
        //   component: () => import('@/views/apps/coupon.vue'),
        // },
        // {
        //   path: "finance/index",
        //   name: "finance/index",
        //   component: () => import('@/views/finance/index')
        // },
        // {
        //   path: "shop/subscriptionLogs",
        //   name: "shop/subscriptionLogs",
        //   component: () => import('@/views/shop/subscriptionLogs')
        // },
        // 财务中心 finances
        {
          path: "finances/wallet",
          name: "finances_wallet",
          component: () => import('@/views/finances/wallet')
        },
        {
          path: "finances/transactionRecord",
          name: "finances_transactionRecord",
          component: () => import('@/views/finances/transactionRecord')
        },
        {
          path: "finances/bankCard",
          name: "finances_bankCard",
          component: () => import('@/views/finances/bankCard')
        },
        {
          path: "finances/paymentPassword",
          name: "finances_paymentPassword",
          component: () => import('@/views/finances/paymentPassword')
        },
      ],
    },*/
];

import axios from "axios";
// import qs from "qs";

const CancelToken = axios.CancelToken;
const service = axios.create({
  baseURL: process.env.VUE_APP_V2_API_URL,
  timeout: 60000,
});

service.interceptors.request.use(
  (config) => {
    // 设置请求头
    config.headers["Content-Type"] = "application/json; charset=UTF-8";
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
    let token = localStorage.getItem("vendor_user_token");
    if (config.auth && !token) {
      console.log("需要登录的接口，却拿不到token");
      // return new Error('Auth fail.');
      let cancel;
      config.cancelToken = new CancelToken(function executor(c) {
        // executor 函数接收一个 cancel 函数作为参数
        cancel = c;
        // console.log(c('Auth fail.'));
      });
      cancel("Auth fail.");
    }
    // console.log(config);
    config.headers["x-auth-token"] = token;
    // config.transformRequest = [
    //   function (data) {
    //     // 在请求之前对data传参进行格式转换
    //     data = qs.stringify(data);
    //     return data;
    //   },
    // ];
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject();
  }
);

service.interceptors.response.use(
  (response) => {
    // console.log("response:", response);
    if (response.status === 200) {
      if (response.data.errcode === 0) {
        return response.data;
      } else {
        if (response.data.errcode === 707) {
          console.log('errmsg: "token is expired:"');
          localStorage.clear();
          window.location.href = "/v2/login";
        } else {
          window.vm.$message({
            showClose: true,
            message: window.vm.$t(response.data.errmsg),
            type: "error",
          });
        }
        return Promise.reject(response.data);
      }
    } else {
      return Promise.reject(new Error(response));
    }
  },
  (error) => {
    console.log("error:", error);
    return Promise.reject(error);
  }
);

export default service;

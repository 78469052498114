import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    state: {
        currentCountry: 0,
        TocurrentCountry: 0,
        fromCountry: '',
        toCountry: '',
        incMinimun: 1,
        shippingPrice: 1,
    },
    mutations: {
        setcurrentCountry(state, currentCountry) {
            localStorage.setItem("currentCountry", currentCountry)
            state.currentCountry = currentCountry
        },
        setShippingPrice(state, shippingPrice) {
            localStorage.setItem("shippingPrice", shippingPrice)
            state.shippingPrice = shippingPrice
        },
        setChannelPrice(state, channelPrice) {
            localStorage.setItem("channelPrice", channelPrice)
            state.channelPrice = channelPrice
        },
        setFromCountry(state, fromCountry) {
            localStorage.setItem("fromCountry", fromCountry)
            state.fromCountry = fromCountry
        },
        setToCountry(state, toCountry) {
            localStorage.setItem("toCountry", toCountry)
            state.toCountry = toCountry
        },
        setTocurrentCountry(state, TocurrentCountry) {
            localStorage.setItem("TocurrentCountry", TocurrentCountry)
            state.TocurrentCountry = TocurrentCountry
        },
        setincMinimun(state, incMinimun) {
            localStorage.setItem("incMinimun", incMinimun)
            state.incMinimun = incMinimun
        }

    }
})

export default store
<template>
  <div>
    <el-container>
      <!-- left -->
      <el-aside class="aside" style="width: 250px">
        <nav-left></nav-left>

      </el-aside>
      <el-container>
        <!-- header -->
        <el-header height>
          <headers></headers>
        </el-header>
        <!-- main -->
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import NavLeft from "@/components/Navleft";
import Headers from "@/components/Header";

export default {
  components: {
    NavLeft,
    Headers,
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/_responsive.scss";
.aside {
//   height: 100vh;
  background: #23262f;
  @include mobile {
    display: none;
  }
}
.el-hread {
  background-color: #fff;
  color: #333;
  line-height: 60px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.el-main {
  background-color: #f6f1f0;
  color: #333;
  min-height: 80vh;
  @include mobile {
    padding: 0;
  }
}
</style>
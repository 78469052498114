<template>
  <div id="app">
    <component :is="layout">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </component>
  </div>
</template>

<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  computed: {
    layout() {
      return this.$route.meta.layout || "empty-layout";
    },
  },
  methods: {
    autoChange() {
      var thisLanguage = localStorage.getItem("thisLanguage");
      if (thisLanguage == "en" || thisLanguage == "zh") {
        this.$i18n.locale = thisLanguage;
      } else {
        switch (navigator.language) {
          case "en":
            this.$i18n.locale = "en";
            break;
          case "zh":
          case "zh-CN":
          case "zh-TW":
          case "zh-HK":
            this.$i18n.locale = "zh";
            break;
        }
      }
    },
  },
  created() {
    this.autoChange();
  },
};
</script>

<style lang="scss">
@import "./style/element_reset.scss";

.imgbg {
  background-image: url("http://oss.sokogate.com/static/photoshop_background.png");
}
</style>
<template>
  <div>
    <!-- #23262F -->
    <div class="left fl">
      <el-menu
        ref="menu"
        class="el-menu-vertical-demo munu-wrap"
        background-color="#23262F"
        text-color="#fff"
        active-text-color="#FF0A0A"
        :default-active="$route.path"
        router
        collapse-transition
        @select="handleSelect"
      >
        <!-- S -->
        <img src="@/assets/S.png" class="navleft-S" />
        <p class="navleft-text mt1">SoKoGate</p>
        <template v-for="(item, index) in list">
          <template v-if="item.children">
            <el-submenu
              :key="`1_${index}`"
              v-if="!item.menu.hidden"
              :index="item.menu.path"
            >
              <template v-slot:title>
                <i v-if="item.menu.meta.icon" :class="item.menu.meta.icon"></i>
                <span>{{ $t(`menu.${item.menu.name}`) }}</span>
              </template>
              <template v-for="(subItem, subIndex) in item.children">
                <el-menu-item
                  v-if="!subItem.menu.hidden"
                  :key="`1_2_${index}_${subIndex}`"
                  :index="subItem.menu.path"
                >
                  <span>{{ $t(`menu.${subItem.menu.name}`) }}</span>
                </el-menu-item>
              </template>
            </el-submenu>
          </template>
          <template v-else>
            <el-menu-item :key="`1_${index}`" :index="item.menu.path">
              <i v-if="item.menu.meta.icon" :class="item.menu.meta.icon"></i>
              <span>{{ $t(`menu.${item.menu.name}`) }}</span>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
    </div>
    <!-- <div class="right fr">
      <p></p>
    </div>
    <div class="clear"></div> -->
  </div>
</template>

<script>
export default {
  computed: {
    list() {
      return this.$router.options.routes;
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.$emit('select');
    },
  },
};
</script>

<style scoped>
.right {
  width: 162px;
  background: #fff;
}
.el-menu-vertical-demo {
  width: 250px;
}
.navleft-S {
  width: 33px;
  height: 33px;
  margin-top: 43px;
  margin-left: 100px;
}
.navleft-text {
  font-size: 20px;
  margin-left: 72px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 60px;
}
.navleft-color {
  border-radius: 40px 0px 0px 40px;
  background-color: #ffffff !important;
}
.navleft-color i {
  color: red !important;
}
.navleft-color span {
  color: red !important;
  font-weight: bold;
}
.is-active {
  border-radius: 40px 0px 0px 40px !important;
  background-color: #ffffff !important;
  font-weight: bold;
}
.home {
  font-size: 15px;
}
span {
  font-size: 15px;
}
.el-menu {
  border: none;
}
.munu-wrap {
  height: 100vh;
}
</style>